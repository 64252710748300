import React from "react";
import "../css/bio.css";
import useIsMobile from "../hooks/useIsMobile";

export type BioPageProps = {
  name: string;
  subheading: string;
  bioIntro: string;
  bio: string;
  imageSrc: string;
};

type ResponsiveSizes = {
  title: string;
  lead: string;
  paragraph: string;
  pagePadding: string;
  imageMargin?: string;
  centerMargin: string;
  alignPage: string;
};

const defaultSizes: ResponsiveSizes = {
  title: "2.25rem",
  lead: "2rem",
  paragraph: "1.25rem",
  pagePadding: "1.875rem",
  centerMargin: "0.9375rem",
  alignPage: "center",
};

const BioPage = (props: BioPageProps) => {
  const isMobile = useIsMobile();

  const responsive: ResponsiveSizes = isMobile
    ? {
        title: "1.75",
        lead: "1.5rem",
        paragraph: "1rem",
        pagePadding: "0.625rem",
        imageMargin: "1rem",
        centerMargin: "0.3125rem",
        alignPage: "flex-start",
      }
    : defaultSizes;

  return (
    <div className="bio-page" style={{ padding: responsive.pagePadding }}>
      <div className="bio-page__title">
        <h1 className="bio-page__name" style={{ fontSize: responsive.title }}>
          {props.name}
        </h1>
        <h4 className="bio-page__sub">{props.subheading}</h4>
      </div>
      <div
        className="bio-page__row"
        style={{ alignItems: responsive.alignPage }}
      >
        <div
          className="bio-page__image"
          style={{
            marginTop: responsive.imageMargin,
            marginRight: responsive.centerMargin,
          }}
        >
          <img src={props.imageSrc} alt={`${props.name}'s profile picture`} />
        </div>
        <div
          className="bio-page__text"
          style={{ marginLeft: responsive.centerMargin }}
        >
          <h3 style={{ fontSize: responsive.lead }}>
            {props.bioIntro}
            <mark style={{ backgroundColor: "#FFFFFF", color: "#5C9AF6" }}>
              .
            </mark>
          </h3>
          <p style={{ fontSize: responsive.paragraph }}>{props.bio}</p>
        </div>
      </div>
    </div>
  );
};

export default BioPage;
