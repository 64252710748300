import React from "react";
import "./css/App.css";
import BioPage, { BioPageProps } from "./pages/BioPage";
import image from "../src/assets/portrait.jpg";
import Footer, { FooterProps } from "./components/Footer";
import {
  faGoogle,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const bioProps: BioPageProps = {
  name: "OLAF_ SCHWEINSBERG_",
  subheading: "Software Engineer | Artist | Solutions Architect",
  bioIntro: "Hi I'm Olaf, an engineer & creator based in Seattle",
  bio:
    "Currently a lead developer at Amazon, Olaf also works independently producing original artwork, " +
    "photography and graphic designs. He built this page and some other stuff " +
    "and he's currently exploring new ventures in the AI/ML space.",
  imageSrc: image,
};

const footerProps: FooterProps = {
  links: [
    { icon: faInstagram, url: "https://www.instagram.com/olafschweinsberg" },
    { icon: faLinkedin, url: "https://www.linkedin.com/in/olafschweinsberg" },
    { icon: faGoogle, url: "mailto:olafcreate@gmail.com" },
  ],
};

function App() {
  return (
    <div className="app-wrapper">
      <BioPage
        name={bioProps.name}
        subheading={bioProps.subheading}
        bioIntro={bioProps.bioIntro}
        bio={bioProps.bio}
        imageSrc={bioProps.imageSrc}
      />
      <Footer links={footerProps.links} />
    </div>
  );
}

export default App;
